// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  companyName: 'ATL',

  // postapiUrl: 'http://postapi.ssw.vn/api',
  // coreapiUrl: 'http://coreapi.ssw.vn/api',
  // crmApiUrl: 'http://crmapi.ssw.vn/api',
  // apiUrl: 'http://cusapi.ssw.vn/api',
  // apiUrl: 'http://localhost:55409/api',

  apiUrl: 'https://atl-post-api.ssw.vn/api',
  coreapiUrl: 'https://atl-post-api.ssw.vn/api',
  postapiUrl: 'https://atl-post-api.ssw.vn/api',
  crmApiUrl: 'https://atl-post-api.ssw.vn/api',

  
  // apiUrl: 'http://localhost:55409//api',
  // coreapiUrl: "http://localhost:55409//api",
  // crmApiUrl:"http://localhost:55409//api",
  // postapiUrl : "http://localhost:55409//api",
  formatDateTimeTable: '',
  formatDateTable: '',
  gMapKey: ''
};
environment.formatDateTimeTable = 'yyyy/MM/dd HH:mm';
environment.formatDateTable = 'yyyy/MM/dd';
environment.gMapKey = 'AIzaSyBu4Y90CByGCKL5rxjR1bfCPAKVkh5KhYA';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
